import {
  storyblokInit,
  apiPlugin,
  renderRichText,
  RichTextSchema,
  ISbRichtext,
} from "@storyblok/react";

export function initStoryblok() {
  storyblokInit({
    accessToken: "5m3jtLk6c0kVoQhRimpQDAtt",
    apiOptions: {
      region: "us", // Pass this key/value if your space was created under US region
    },
    richText: topiaRichTextConfig,
    use: [apiPlugin],
    components: {},
  });
}

export const topiaRichTextConfig = {
  schema: {
    nodes: {
      ...RichTextSchema.nodes,
    },
    marks: {
      ...RichTextSchema.marks,
    },
  },
  resolver: (component, blok) => {
    console.log("resolving", component);
    switch (component) {
      case "paragraph":
        return <p className="text-white my-2">{blok.text}</p>;
      default:
        return <p className="text-red-500 ">Unknown component {component}</p>;
    }
  },
};

function cleanRichText(nodes: ISbRichtext[]): ISbRichtext[] {
  const ret = [];
  for (let node of nodes) {
    let newNode = { ...node };
    if (
      newNode.type === "link" &&
      newNode.attrs &&
      newNode.attrs.linktype === "story"
    ) {
      newNode.attrs.href = "/fipreneurs/article/" + newNode.attrs.uuid;
      newNode.attrs.target = "_blank";
    }

    ret.push({
      ...newNode,
      content: node.content ? cleanRichText(node.content) : undefined,
      marks: node.marks ? cleanRichText(node.marks) : undefined,
    });
  }
  return ret;
}

export function topiaRichText(rt: ISbRichtext) {
  return renderRichText(
    {
      ...rt,
      content: rt.content ? cleanRichText(rt.content) : undefined,
      marks: rt.marks ? cleanRichText(rt.marks) : undefined,
    },
    topiaRichTextConfig
  );
}
