import { StepType } from "@reactour/tour";

export const FISteps: StepType[] = [
  {
    selector: "#step-1",
    content:
      "This is your FI scorecard, track your path to the varying types of FI Flavors.",
  },
  // {
  //   selector: "#step-2",
  //   content:
  //     "Use the Freedom Explorer to see how much freedom you have right now and make a plan to quit your job. You could use that freedom to start a side business and never return to full-time work again.",
  // },
  {
    selector: "#step-3",
    content:
      "This is your projected net worth based on your FI Plan. Use 'Your Plan' to add life events and forecast changes in income and expenditure to make this projection accurately fit your journey.",
  },
  {
    selector: "#step-4",
    content:
      "Once you link your accounts to your Topia, you can track your account balances here or in the portfolio tab.",
  },
];
export const CashflowFISteps: StepType[] = [
  {
    selector: "#step-5",
    content:
      "CashFlow FI is when you generate enough passive and semi-passive income to cover your monthly expenses. Instead of pinching pennies for years and reaching your FI number, it's more than possible to reach FI in less than 6 months by focusing on setting up side businesses in your free time.",
  },
  {
    selector: "#step-6",
    content:
      "This speedometer shows your progress to CashFlow FI, a new way to look at your journey to FI.",
  },
  {
    selector: "#step-7",
    content:
      "Your delta is simply the amount of extra cashflow you need to generate every month to achieve CashFlow FI.",
  },
  {
    selector: "#step-8",
    content:
      "Not sure where to start on growing your passive income? When you’re ready to get serious, our coaching program can help you start and scale your side businesses, close the gap between where you are and where you need to be, and accelerate your journey to FI.",
  },
];
export const FIPlanSteps: StepType[] = [
  {
    selector: "#step-9",
    content:
      "This is your FI plan. Watch these numbers change as you tinker with your income, expenditure and life events in the sections below. The graph gives you a visual representation of your FI journey and projected net worth over time!",
  },
  // {
  //   selector: "#step-10",
  //   content:
  //     "Adjust your FI Number, and take a look at alternative paths to FI like Barista FI and Coast FI here",
  // },
  {
    selector: "#step-11",
    content:
      "In the cash flow section, you can tinker your monthly net income, add sidehustles, add rental income, set your monthly expenses, and even plan future income changes!",
  },
  {
    selector: "#step-12",
    content:
      "In the life event section, you can add life events like having a child, going on a holiday or add any other kind of future expenditure you may have!",
  },
  {
    selector: "#step-13",
    content:
      "In the portfolio planning section you are able to see the impact that big investments and portfolio events might have on your FI Journey, such as buying a rental property, selling your home, or investing a bonus.",
  },
];
export const TourSteps: StepType[] = [
  ...FISteps,
  ...CashflowFISteps,
  ...FIPlanSteps,
];
