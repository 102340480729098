import { ISbStoryData, getStoryblokApi } from "@storyblok/react";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { appActions, useAppDispatch } from "../store";
import _ from "lodash";

export const FIPReneurContext = React.createContext<{
  loading: boolean;
  legos: any[];
  playbooks: any[];
}>(undefined as any);

export function FIPReneurProvider(props: { children: any }) {
  const [state, setState] = useState({
    loading: true,
    legos: [],
    playbooks: [],
  });

  const appDispatch = useAppDispatch();

  useEffect(() => {
    async function load() {
      const storyblokApi = getStoryblokApi();
      const [legos, playbooks] = await Promise.all([
        storyblokApi.getStories({
          content_type: "hustle_lego",
        }),
        storyblokApi.getStories({
          content_type: "playbook",
        }),
      ]);
      setState({
        loading: false,
        legos: _.orderBy(
          legos.data.stories,
          (st) => st.content.sort_order || 0
        ),
        playbooks: playbooks.data.stories,
      });
    }
    load();
  }, []);
  return (
    <FIPReneurContext.Provider value={state}>
      {props.children}
    </FIPReneurContext.Provider>
  );
}

export const useFIPReneurContext = () => useContext(FIPReneurContext);
