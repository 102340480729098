import React, { useEffect, useRef, useState } from "react";

import {
  calculateBaseValues,
  calculateFinancialProjection,
  calculateFreedomDays,
  calculateLifeEventImpactByMonth,
  cleanFinancialProjection,
  redistributeMonthlyTopupForContribution,
  transformIncomesToLifeEvents,
  calculateWithdrawalRate,
  calculateDateNetWorth,
  calculateFullModel,
} from "@byundefined/topia-model";
import moment, { Moment } from "moment";
import {
  Account,
  AccTopupImpact,
  CalculatedAccounts,
  CalculatedSavings,
  DateYMString,
  Income,
  LifeEvent,
  Settings,
} from "@byundefined/topia-model/lib/commonTypes";
import _ from "lodash";
import { ACCOUNT_CATEGORIES, ACCOUNT_SUBTYPES, ACCOUNT_TYPES } from "../enums";

export interface UseModelArgs {
  accounts?: Account[];
  lifeEvents?: LifeEvent[];
  settings?: Settings;
  incomes?: Income[];
  origin?: string;
}

const useFinancialModel = (args: UseModelArgs | undefined) => {
  let { accounts, lifeEvents, settings, incomes } = args || {};
  // console.log("GOT ARGS", args)
  incomes = incomes || [];
  const [FIDate, setFIDate] = useState<Moment>();
  const [FINr, setFINr] = useState<number>(0);
  const [coastFIDate, setCoastFIDate] = useState<DateYMString>();
  const [coastFINr, setCoastFINr] = useState<number>();
  const [netWorthInfo, setNetworthInfo] =
    useState<ReturnType<typeof calculateDateNetWorth>>();
  const [calculatedSavings, setCalculatedSavings] =
    useState<CalculatedSavings>();
  const [fullModel, setFullModel] =
    useState<ReturnType<typeof calculateFullModel>>();
  const [calculatedSavingsStatic, setCalculatedSavingsStatic] = useState({});
  const [version, setVersion] = useState<number | undefined>(undefined);
  const [isCalculating, setIsCalculating] = useState(false);
  const calcTimeoutRef = useRef<any>();

  useEffect(() => {
    if (!args) return;

    if (calcTimeoutRef.current) {
      clearTimeout(calcTimeoutRef.current);
    }

    setIsCalculating(true);

    calcTimeoutRef.current = setTimeout(() => {
      if (
        accounts &&
        lifeEvents &&
        incomes &&
        settings &&
        settings?.hasOwnProperty("monthlyTakehome")
      ) {
        // Calculate the base values (FI Nr etc.)
        const modelInput = {
          accounts,
          lifeEvents,
          settings,
          incomes,
        };
        console.log("💰 modelInput", args.origin, modelInput);
        const modelResult = calculateFullModel(modelInput);
        console.log(args.origin, modelResult);

        // console.log("@Model", {
        //   input: args,
        //   output: fullModel
        // })
        setFullModel(modelResult);

        // @ts-ignore
        setCalculatedSavings(modelResult.calculatedAccountsYearly);
        setCalculatedSavingsStatic(modelResult.calculatedAccountsMonthly);

        const networth = calculateDateNetWorth(accounts);

        if (modelResult.projectedGrowthAmount) {
          setCoastFINr(modelResult.projectedGrowthAmount);
          setCoastFIDate(modelResult.projectedGrowthReachedAt);
        } else {
          setCoastFINr(undefined);
          setCoastFIDate(undefined);
        }

        setNetworthInfo(networth);

        // @ts-ignore
        if (settings.postFIType === "coast" && settings.coastFIAge) {
          // Hardcode to coast fi for now
          // @ts-ignore
          setFIDate(
            moment(settings.dateOfBirth, "DD/MM/YYYY").add(
              settings.coastFIAge,
              "years"
            )
          );
        } else {
          setFIDate(modelResult.FIDate);
        }

        setVersion(Date.now());
        setFINr(modelResult.FINr);
        setIsCalculating(false);
      }
    });
  }, [JSON.stringify(args)]);

  const canReachFI =
    FIDate && moment(FIDate).isAfter("2005-01-01T00:00:00.000Z");

  let FIDateString: string;
  if (canReachFI) {
    FIDateString = FIDate!.format("MMM YYYY");
  } else {
    FIDateString = "N/A";
  }

  let timeToFIString: string;
  if (canReachFI) {
    timeToFIString = formatDaysToFI(FIDate!.toDate(), true);
  } else {
    timeToFIString = "N/A";
  }

  const FIReached = FINr && FINr < (netWorthInfo?.netWorth || 0);

  return {
    FIDate,
    FINr,
    FIDateString,
    FIReached,
    timeToFIString,
    calculatedSavings,
    calculatedSavingsStatic,
    coastFIDate,
    coastFINr,
    canReachFI,
    version,
    fullModel,
    netWorthInfo,
    isCalculating: !args || isCalculating,
  };
};

export type FinancialModelResult = ReturnType<typeof useFinancialModel>;

export { useFinancialModel };

function formatDaysToFI(date: Date, useShortNotation = false) {
  const months = moment(date).diff(moment(), "months") % 12;
  const years = (moment(date).diff(moment(), "months") - months) / 12;
  let monthsLabel = useShortNotation ? "month" : "mth";
  if (months !== 1) monthsLabel += "s";
  let yearsLabel = useShortNotation ? "year" : "yr";
  if (years !== 1) yearsLabel += "s";

  const timeToFI = `${years} ${yearsLabel}, ${months} ${monthsLabel}`;

  return timeToFI;
}

export type UseFinancialModelResult = ReturnType<typeof useFinancialModel>;

export function findNetWorthGoalDate(
  modelOutput: CalculatedSavings,
  goalNumber: number
) {
  let nwData = Object.keys(modelOutput).map((key) => {
    const date = moment(key, "YYYY-MM").toDate();
    return {
      id: key,
      date: date.toISOString(),
      value: modelOutput[key].total,
    };
  });
  nwData = nwData.filter((x) => new Date(x.date) > new Date());

  nwData.sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
  );

  return nwData.find((x) => x.value >= goalNumber)?.date;
}
