import { FirebaseOptions, initializeApp } from "firebase/app";
import * as fbFunctions from "firebase/functions";
import * as fbFirestore from "firebase/firestore";
import * as fbAuth from "firebase/auth";
import { connectFirestoreEmulator } from "firebase/firestore";
import { connectAuthEmulator } from "firebase/auth";
import { connectFunctionsEmulator } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyC2b8oO0aSDMI-ZBFnRMMsDwTdwpKL4xSw",
  authDomain: "fipreneurs-staging.firebaseapp.com",
  projectId: "fipreneurs-staging",
  storageBucket: "fipreneurs-staging.appspot.com",
  messagingSenderId: "635942113226",
  appId: "1:635942113226:web:ea52a3445867e40ad54933",
};

const app = initializeApp(firebaseConfig);

export const auth = fbAuth.getAuth(app);
export const db = fbFirestore.getFirestore(app);
export const functions = fbFunctions.getFunctions(app, "europe-west2");

// if (firebaseEnv === "emulator") {
//   connectFirestoreEmulator(db, "localhost", 9090);
//   connectAuthEmulator(auth, "http://localhost:9099", {
//     disableWarnings: true,
//   });
//   connectFunctionsEmulator(functions, "localhost", 5001);
// }
