import classNames from "classnames";

export function Card(props: { children?: any; className?: string }) {
  return (
    // <div className="   border-r-4 border-b-4 border-gray-500">
    // <div className={classNames(" border-2 border-black mb-4", props.className)}>
    <div
      className={classNames(
        "border border-white/20 rounded-xl p-4",
        props.className
      )}
    >
      {props.children}
    </div>
  );
}
