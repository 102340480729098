import React, { useState } from "react";
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
  createRoutesFromElements,
  Navigate,
  RouteProps,
} from "react-router-dom";
import { useAuthContext } from "./auth";
import { ApplicationShell } from "./components/ApplicationShell";
import { FullScreenTopiaLoader } from "./components/core/TopiaLoader";
import { ErrorPage } from "./pages/ErrorPage";
import { LoginPage } from "./pages/LoginPage";
import { useAppSelector, useIsLoadingGlobal } from "./store";
import ForgotPassWordPage from "./pages/ForgotPasswordPage";
import { HomePage } from "./pages/HomePage";

function ProtectedRoute(props: { children: any }) {
  const authCtx = useAuthContext();
  const globalLoading = useIsLoadingGlobal();
  const needsFiVitals = useAppSelector((state) => state.app.needsFIVitals);

  if (!authCtx.loading && !authCtx.user) {
    return <Navigate to="/login" />;
  }

  if (globalLoading) {
    return <FullScreenTopiaLoader />;
  }

  if (needsFiVitals) {
    return <Navigate to="/onboarding" />;
  }

  return props.children;
}

function ApplicationRoute(children: JSX.Element) {
  return (
    <ProtectedRoute>
      <ApplicationShell>{children}</ApplicationShell>
    </ProtectedRoute>
  );
}

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route
        errorElement={<ErrorPage />}
        path="/login"
        element={<LoginPage />}
      />
      <Route
        errorElement={<ErrorPage />}
        path="/forgot-password"
        element={<ForgotPassWordPage />}
      />
      <Route
        errorElement={<ErrorPage />}
        path="/"
        element={ApplicationRoute(<HomePage />)}
      />
      <Route errorElement={<ErrorPage />} path="*" element={<LoginPage />} />
    </>
  )
);
