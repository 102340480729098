import { useState } from "react";
import { useModalContext } from "../context/ModalContext";
import { TopiaButton } from "./core/TopiaButton";

export type TopiaPromptModalProps = {
  title: string;
  body: React.ReactElement | string;
  actions: {
    label: string;
    onClick: () => void | Promise<void>;
  }[];
};

export function TopiaPromptModal(props: TopiaPromptModalProps) {
  const modalCtx = useModalContext();
  const [loading, setLoading] = useState(false);

  return (
    <div className="p-4">
      <div>
        <h3 className="text-lg leading-6 font-medium text-white">
          {props.title}
        </h3>
        <div className="mt-2">
          {typeof props.body === "string" ? (
            <p className="text-sm text-white">{props.body}</p>
          ) : (
            props.body
          )}
        </div>
      </div>
      <div className="flex">
        {props.actions.map((action, i) => (
          <TopiaButton
            key={i}
            className="mt-8"
            onClick={async () => {
              setLoading(true);
              try {
                await action.onClick();
                modalCtx.closeModal();
              } catch (ex) {
                console.error(ex);
                // TODO: Error handling
              } finally {
                setLoading(false);
              }
            }}
            loading={loading}
          >
            {action.label}
          </TopiaButton>
        ))}
      </div>
    </div>
  );
}
