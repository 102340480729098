import classNames from "classnames";
import { trackAnalyticsEvent } from "../../analytics";
import { TopiaLoader } from "./TopiaLoader";

export function TopiaButton(props: {
  children: any;
  onClick?: (val: any) => void | Promise<void>;
  type?: "submit";
  linkTo?: string;
  analyticsEvent?: string;
  textClassName?: string;
  className?: string;
  btnStyle?: "primary" | "secondary" | "future" | "white-outline";
  loading?: boolean;
}) {
  return (
    <button
      type={props.type || "button"}
      onClick={(val) => {
        if (props.analyticsEvent) {
          trackAnalyticsEvent(props.analyticsEvent);
        }
        props.onClick && props.onClick(val as any);
      }}
      disabled={props.loading}
      style={{
        minWidth: 180,
        borderRadius: 50,
        height: 50,
      }}
      className={classNames(
        `text-center inline-flex items-center align-center justify-center border  px-4 py-2 font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2 border-2`,
        !props.btnStyle || props.btnStyle === "primary"
          ? "bg-pale-yellow text-topia-black border-topia-black "
          : "",
        props.btnStyle === "future" &&
          "bg-future-blue text-black focus:ring-gray-500 border-topia-black",
        props.btnStyle === "secondary" &&
          "bg-topia-black text-white focus:ring-white border-topia-black",
        props.btnStyle === "white-outline" &&
          "border-white bg-topia-black text-white focus:ring-white",
        props.className
      )}
    >
      <p className={`text-md m-auto ${props.textClassName}`}>
        {props.loading ? <TopiaLoader className="" /> : props.children}
      </p>
    </button>
  );
}
