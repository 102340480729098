import { format } from "path";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useAuthContext } from "../auth";
import { TopiaButton } from "../components/core/TopiaButton";
import {
  TopiaEmailInput,
  TopiaPasswordInput,
  TopiaTextInput,
} from "../components/core/TopiaInput";
import { TopiaLogo } from "../components/core/TopiaLogo";
import * as Sentry from "@sentry/react";

export function HomePage() {
  return (
    <div>
      <h1>fipreneurs</h1>
      <h1>fipreneurs</h1>
    </div>
  );
}
