import {
  calculateDateNetWorth,
  generateFullUserSettings,
  getMonthlyTakehomeFromIncome,
} from "@byundefined/topia-model";
import {
  Account,
  UserModel,
  Income,
  IncomeTypes,
  LifeEvent,
  Settings,
  DateYMString,
  DateYMDString,
} from "@byundefined/topia-model/lib/commonTypes";
import {
  collection,
  doc,
  query,
  where,
  setDoc,
  addDoc,
  getDoc,
} from "@firebase/firestore";
import {
  deleteDoc,
  DocumentData,
  DocumentReference,
  getDocs,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import moment from "moment";
import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
  useRef,
} from "react";
import { updateDo } from "typescript";
import { useAuthContext } from "../auth";
import {
  FullScreenTopiaLoader,
  TopiaLoader,
} from "../components/core/TopiaLoader";
import { ACCOUNT_CATEGORIES, ACCOUNT_SUBTYPES } from "../enums";
import { db } from "../firebase";
import {
  FinancialModelResult,
  UseModelArgs,
  useFinancialModel,
} from "../hooks/useFinancialModel";
import { appActions, useAppDispatch, useAppSelector } from "../store";
import { SavingsRateDatum } from "../topia-graph/TopiaSavingsRateGraph";

const DEBUG_LOG_SUBS = false;

// TODO: Verify types with Siebe
// Do we have an existing type for the User collection?

// TODO: Figure out how to filter things like plaid access token out

// export type UserModel = {
//   incomes: Income[];
//   settings: Settings;
//   initialSettings: Settings;
//   deviceRegion: string;
//   region: "us" | "gb";
//   registeredOn: any;
//   email?: string;
//   modelResult: FinancialModelResult;
//   codepushLabel?: string;
//   displayProgressReport?: boolean;
//   lastActiviyDate?: Date;
//   topiaPlusWaitlist?: {
//     joinedOn: Date;
//   };
//   history: {
//     [key: DateYMString]: {
//       FIDate: string;
//       FINr: number;
//       netWorth: number;
//       investmentContribution?: number; // New field only available from 1.6
//     };
//   };
// };

// TODO: Move into models
type FIPreneurData = {
  sidehustles: {
    [key: string]: {
      isDoing: boolean;
    };
  };
};

type IDataContext = {
  uid: string;
  fipreneurData: FIPreneurData;
  modelArgs: UseModelArgs;
  user: UserModel;
  accounts: Account[];
  lifeEvents: LifeEvent[];
  modelResult: FinancialModelResult;
  displayProgressReport: boolean;
  newToFI: boolean;
  topiaRegion: "us" | "gb";
  userRef: any;
  lastMonthNetWorth: number;
  history: SavingsRateDatum[];
  estNetWorthAccts: Account[];
  getAccounts: () => Promise<Account[]>;
  getUser: () => Promise<UserModel>;
  // TODO: Fix types on all of these operations
  operations: {
    updateAccount: (data: { id: string; data: any }) => Promise<void>;
    removeAccount: (data: { id: string }) => Promise<void>;
    createUserGeneratedAccount: (data: Account) => Promise<void>;
    updateSettings: (data) => Promise<void>;
    updateLastAccountContribution: (data: any) => Promise<void>;
    confirmRecentlyAddedAccounts: (data: {
      updatedAccounts: any;
    }) => Promise<void>;
    updateIncome: (data: { income: Income[] }) => Promise<void>;
    updateRegion: (newRegion: "us" | "gb") => Promise<void>;
  };
};

const DataContext = createContext<IDataContext>(undefined as any);

const DataProvider = ({ children }: { children: any }) => {
  const authCtx = useAuthContext();
  const appDispatch = useAppDispatch();
  const [rawUser, setRawUser] = useState<UserModel | undefined>(undefined);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [lifeEvents, setLifeEvents] = useState<LifeEvent[]>([]);
  const [userRef, setUserRef] = useState<any | undefined>();
  const initialized = useAppSelector((state) => state.app.initialized);
  const [history, setHistory] = useState<SavingsRateDatum[]>([]);

  const uid = authCtx?.user?.uid;

  useEffect(() => {
    if (userRef) {
      appDispatch(appActions.initialize({}));
    }
    if (userRef && rawUser) {
      updateDoc(userRef, {
        lastActivityDate: new Date(),
      });
    }
  }, [userRef]);

  useEffect(() => {
    if (uid) {
      // @ts-ignore
      function onError(type) {
        return (err) => {
          console.log("Data error: ", type);
          // TODO @urgent error handling
          // console.error('Data error', err);
          throw err;
        };
      }

      const _userRef = doc(collection(db, "users"), uid);
      const _accountsRef = collection(_userRef, "accounts");
      const _lifeEventRef = query(
        collection(db, "lifeEvents"),
        where("userId", "==", uid)
      );

      setUserRef(_userRef);

      const removeUserSubscription = onSnapshot(
        _userRef,
        (snapshot) => {
          if (DEBUG_LOG_SUBS)
            console.log("👤 User snapshot: ", snapshot.data());
          setRawUser(snapshot.data() as any);
        },
        onError("user")
      );

      const removeAccountsSubscription = onSnapshot(
        _accountsRef,
        (snapshot) => {
          if (DEBUG_LOG_SUBS) console.log("💵 Accounts snapshot: ", snapshot);
          setAccounts(
            snapshot.docs.map(
              (d) =>
                ({
                  id: d.id,
                  ...d.data(),
                } as any)
            )
          );
        },
        onError("accounts")
      );

      const removeLifeEventsSubscription = onSnapshot(
        _lifeEventRef,
        (snapshot) => {
          if (DEBUG_LOG_SUBS)
            console.log("🏠 Life Events snapshot: ", snapshot);
          const docs = snapshot.docs.map(
            (d) =>
              ({
                id: d.id,
                ...d.data(),
              } as any)
          );

          // const count = {};
          // for(let d of snapshot.docs) {
          //   const id = d.data().id;
          //   count[id] = (count[id] || 0) + 1;
          //   if (count[id] > 1) {
          //     console.log("count", count, snapshot.docs)
          //     console.log("Got duplicate", id, d.ref.id)
          //     FB_DB.collection('lifeEvents').doc(d.ref.id).delete();
          //   }
          // }

          setLifeEvents(docs);
        },
        onError("lifeEvents")
      );

      return () => {
        removeUserSubscription();
        removeAccountsSubscription();
        removeLifeEventsSubscription();
      };
    }
  }, [uid]);

  const needsFiVitals = useAppSelector((state) => state.app.needsFIVitals);
  if (!needsFiVitals && rawUser && !rawUser.settings) {
    appDispatch(appActions.setNeedsFIVitals(true));
  } else if (needsFiVitals && rawUser && rawUser.settings) {
    appDispatch(appActions.setNeedsFIVitals(false));
  }

  const user: UserModel | undefined = rawUser
    ? {
        ...rawUser,
        settings: _autoCalculateSettings(rawUser),
      }
    : undefined;

  if (user) {
    if (user.settings && !user.settings?.dateOfBirth) {
      user.settings.dateOfBirth = "1/1/2000" as any;
    }

    user.incomes = user.incomes || [
      {
        type: "salary",
        name: "Salary",
        id: "static-salary",
        amount: Number(user?.settings.monthlyTakehome || 3000),
      },
    ];

    const salary = user.incomes.find(
      (inc: Income) => !inc.disabled && inc.type === IncomeTypes.SALARY
    );

    if (!salary) {
      user.incomes.push({
        id: "static-salary",
        type: "salary",
        amount: user.settings.monthlyTakehome || 3000,
        name: "Salary",
      });
    }
  }

  const fipreneurData = ((user as any)?.fipreneur as FIPreneurData) || {
    sidehustles: {},
  };

  let formattedAccounts = user?.settings
    ? formatAccounts(accounts, user?.settings)
    : accounts;

  const lastMonth = moment().add(-1, "month").format("YYYY-MM");
  const lastMonthNetWorth = (user?.history || {})[lastMonth]?.netWorth || 0;

  let estNetWorthAccts: Account[] = [
    {
      name: "Est Net Worth",
      // @ts-ignore
      amount: Number(user?.settings.estimatedNetWorth || 0),
      annualReturn: 7,
      category: "isa",
      history: {},
      id: "static-est-net-worth",
      subType: "stocks_shares_isa",
      monthlyTopup: 0,
      type: "saving",
      userID: "asdasdasd",
    },
  ];

  const modelArgs: UseModelArgs = {
    accounts:
      user?.settings && formattedAccounts.length === 0
        ? estNetWorthAccts
        : formattedAccounts,
    lifeEvents,
    settings: {
      ...user?.settings,
      retirementIncome:
        user?.settings?.postFIType === "barista"
          ? user.settings.retirementIncome
          : 0,
    },
    incomes: user?.incomes,
    origin: "dataCtx",
  };

  const modelResult = useFinancialModel(modelArgs);

  /**
   * Sync the data ctx state with the store
   */
  useEffect(() => {
    if (user) {
      global.region = user.region;
      appDispatch(appActions.setUser(user));
    }
  }, [rawUser]);

  useEffect(() => {
    if (accounts) {
      appDispatch(appActions.setAccounts(formattedAccounts));
    }
  }, [accounts]);

  useEffect(() => {
    if (lifeEvents) {
      appDispatch(appActions.setLifeEvents(lifeEvents));
    }
  }, [lifeEvents]);

  useEffect(() => {
    if (modelResult) {
      appDispatch(appActions.setModelResult(modelResult));
    }
  }, [modelResult.version]);

  /**
   * TODO: Should this be in dataCtx?
   * Calculate history and investment rate
   */
  useEffect(() => {
    const filteredAccs = accounts.filter((acc) => !acc.disabled);
    const currentMonth = moment().format("YYYY-MM");
    let historyInvestments = {};

    accounts.forEach((acct) => {
      if (acct.history) {
        Object.keys(acct.history).forEach((histDate) => {
          if (!historyInvestments[histDate] && histDate !== currentMonth) {
            // Make sure to not use the current month for the history as that history is not complete yet
            historyInvestments[histDate] = 0;
          }
        });
      }
    });

    const newHistory = Object.keys(historyInvestments)
      .map((date) => {
        const netWorth = calculateDateNetWorth(
          filteredAccs,
          date as DateYMString
        );

        const salary = user?.settings.monthlyTakehome;
        let savingsRate = Math.floor(
          (netWorth.investmentContribution / salary) * 100
        );

        if (savingsRate > 100) {
          savingsRate = 100;
        }

        return {
          // ...obj,
          date: date,
          dateFormatted: moment(date).format("MMM yy"),
          investment: netWorth.investmentContribution,
          expenditure:
            user?.settings.monthlyTakehome - (historyInvestments[date] || 0),
          staticSavingRate: savingsRate,
          staticIncome: salary,
        };
      })
      .sort((a, b) => {
        return moment(`${a.date}-01`).isBefore(`${b.date}-01`) ? 1 : -1;
      });

    setHistory(newHistory);
  }, [accounts, rawUser]);

  // useEffect(() => {
  //   if (modelResult) {
  //     userRef().update({
  //       "modelResult.FINr": modelResult?.FINr,
  //       "modelResult.FIDate": modelResult?.FIDate?.toDate().toISOString(),
  //       "modelResult.canReachFI": modelResult?.canReachFI,
  //       "modelResult.netWorth": modelResult.netWorthInfo.netWorth,
  //     });
  //   }
  // }, [modelResult?.version]);

  // TODO: Review with Logan
  const newToFI = false; //Boolean(modelResult?.netWorthInfo && modelResult.netWorthInfo.netWorth < 50000);

  async function getUser(): Promise<UserModel> {
    return (await userRef?.get())?.data() as UserModel;
  }

  async function getAccounts(): Promise<Account[]> {
    const rawAccs = (await getDocs(collection(userRef, "accounts"))).docs.map(
      (d) =>
        ({
          id: d.id,
          ...d.data(),
        } as any)
    );

    const formattedAccounts = user?.settings
      ? formatAccounts(rawAccs, user?.settings)
      : rawAccs;

    return formattedAccounts;
  }

  let topiaRegion: "us" | "gb";
  if (user && user.region) {
    topiaRegion = user.region === "gb" ? "gb" : "us";
  } else {
    // const deviceRegion = (RNLocalize.getCountry() || 'us').toLowerCase();
    const deviceRegion: string = "us";
    if (user && userRef && user.deviceRegion !== deviceRegion) {
      updateDoc(userRef, { deviceRegion });
    }

    topiaRegion = deviceRegion === "gb" ? "gb" : "us";
    if (user && userRef && !user.region) {
      updateDoc(userRef, { region: topiaRegion });
    }
  }
  global.region = topiaRegion;

  // useEffect(() => {
  //   if (rawUser && userRef) {
  //     userRef.update({
  //       "settings.retirementSpent": 7000
  //     });
  //   }
  // }, [rawUser])

  const operations: IDataContext["operations"] = {
    async updateAccount({ id, data }) {
      let updatedData = { ...data };
      const accDoc = doc(collection(userRef, "accounts"), id);
      const currentAccData = (await getDoc(accDoc)).data();

      const lastMonth = moment().add(-1, "month").format("YYYY-MM");

      // TODO: @Siebe
      // I commented out this too, let's discuss

      if (typeof data.monthlyTopup !== "undefined") {
        updatedData.monthlyTopup = Number(data.monthlyTopup);
        updatedData.manualMonthlyTopup = data.monthlyTopup; // Value is most likely manually been set by customer, so lets update manualMonthlyTopup
      }
      //   updatedData.monthlyTopup = parseFloat(data.monthlyTopup);

      //   if (data.history) {
      //     updatedData.history[lastMonth] = {
      //       ...(updatedData.history[lastMonth] || {}),
      //       monthlyTopup: parseFloat(data.monthlyTopup),
      //     };
      //   } else {
      //     // When someone updates the monthlytopup, we should also update the history so that it correclty matches the investment rate
      //     updatedData[`history.${lastMonth}.monthlyTopup`] = parseFloat(
      //       data.monthlyTopup
      //     );
      //   }
      // }

      if (typeof data.extraTopup !== "undefined") {
        updatedData.extraTopup = parseFloat(data.extraTopup);
      }

      // TODO: @Siebe
      //  I commented out the below block, won't it always wipe out the passed in topup with the last month history?
      //  Which I guess is set above based on the passed in monthlyTopup but I'm not sure if what's correct

      // In fact, ignore this, let's discuss together during code review

      // if (typeof data.history !== "undefined" && data.history[lastMonth]) {
      //   // Last months history data has been updated, so we also need to sync the monthly topup
      //   updatedData.monthlyTopup = parseFloat(
      //     data.history[lastMonth].monthlyTopup
      //   );

      //   if (currentAccData.hasOwnProperty("manualMonthlyTopup")) {
      //     updatedData.manualMonthlyTopup = parseFloat(
      //       data.history[lastMonth].monthlyTopup
      //     );
      //   }
      // }

      if (
        data.hasOwnProperty("disabled") &&
        data.disabled !== currentAccData.disabled &&
        currentAccData.hasLifeEvent
      ) {
        // TODO: Fix
        // Check if there is a lifeEvent with the account id
        // const connectedlifeEvent = await FB_DB.collection('lifeEvents')
        //   .where('userId', '==', uid)
        //   .where('accountId', '==', id)
        //   .get();
        // //TODO: do we need to do the same query again?
        // connectedlifeEvent.docs.map(async (lifeEvRef) => {
        //   await lifeEvRef.ref.update({
        //     disabled: data.disabled,
        //   });
        // });
      }

      // // Format all the dates correctly
      // if (data.dateAccessed) {
      //   updatedData.dateAccessed = moment(data.dateAccessed)
      //     .startOf("month")
      //     .format("YYYY-MM-DD") as DateYMDString;
      // }

      console.log("Updating", updatedData);

      await updateDoc(accDoc, cleanObject(updatedData));
    },
    async createUserGeneratedAccount(account: Account) {
      let updatedData = {
        ...account,
        userID: uid,
      };

      // TODO: Do we still use dateAccessed

      if (account.dateAccessed) {
        updatedData.dateAccessed = moment(account.dateAccessed)
          .startOf("month")
          .format("YYYY-MM-DD") as DateYMDString;
      }

      if (!updatedData.history) {
        updatedData.history = {
          [moment().subtract(1, "months").format("YYYY-MM")]: {
            // Let's already save last month as history as we can assume the current value in the account was what was available at the end of last month
            amount: Number(account.amount || 0),
            monthlyTopup: Number(updatedData.monthlyTopup || 0),
            // TODO: extraTopup still used?
            // @ts-ignore
            extraTopup: Number(updatedData.extraTopup || 0),
            annualReturn: Number(updatedData.annualReturn || 0),
            createdDuringNextMonth: true, // This flags that the account was created in the next month (for comparison)
          },
        };
      }

      // @ts-ignore
      updatedData.updatedOn = new Date();
      updatedData.userGenerated = true;

      console.log("updatedData", updatedData);

      if (account.id) {
        await setDoc(
          doc(collection(userRef, "accounts"), account.id),
          cleanObject(updatedData)
        );
      } else {
        await addDoc(collection(userRef, "accounts"), cleanObject(updatedData));
      }
    },
    async updateSettings(data: any) {
      const {
        monthlyTakehome,
        monthlySavings,
        monthlySpend,
        withdrawalRate,
        retirementSpent,
        retirementIncome,
        inflation,
        basicTotalSavings,
        partnerEnabled,
        partnerName,
        dateOfBirth,
        shouldConfirmIncome,
        retiremenCoastIncome,
        pledgeTarget,
        postFIType,
        investmentGrowthRate,
        coastFIAge,
        estimatedNetWorth,
      } = data;

      let newObj: any = {};
      let settings;

      if (typeof estimatedNetWorth !== "undefined") {
        newObj["settings.estimatedNetWorth"] = estimatedNetWorth;
      }
      if (typeof withdrawalRate !== "undefined") {
        newObj["settings.withdrawalRate"] = withdrawalRate;
      }
      if (typeof retiremenCoastIncome !== "undefined") {
        newObj["settings.retiremenCoastIncome"] = retiremenCoastIncome;
      }

      if (typeof monthlyTakehome !== "undefined") {
        newObj["settings.monthlyTakehome"] = monthlyTakehome;
      }
      if (typeof postFIType !== "undefined") {
        newObj["settings.postFIType"] = postFIType;
      }

      if (typeof retirementSpent !== "undefined") {
        newObj["settings.retirementSpent"] = retirementSpent;
      }
      if (typeof dateOfBirth !== "undefined") {
        newObj["settings.dateOfBirth"] = dateOfBirth;
      }

      if (typeof retirementIncome !== "undefined") {
        newObj["settings.retirementIncome"] = retirementIncome;
      }
      if (typeof monthlySavings !== "undefined") {
        newObj["settings.monthlySavings"] = monthlySavings;
      }
      if (typeof monthlySpend !== "undefined") {
        newObj["settings.monthlySpend"] = monthlySpend;
      }
      if (typeof inflation !== "undefined") {
        newObj["settings.inflation"] = inflation;
      }
      if (typeof basicTotalSavings !== "undefined") {
        newObj["settings.basicTotalSavings"] = basicTotalSavings;
      }
      if (typeof partnerEnabled !== "undefined") {
        newObj["settings.partnerEnabled"] = partnerEnabled;
      }
      if (typeof partnerName !== "undefined") {
        newObj["settings.partnerName"] = partnerName;
      }
      if (typeof shouldConfirmIncome !== "undefined") {
        newObj["settings.shouldConfirmIncome"] = shouldConfirmIncome;
      }
      if (typeof pledgeTarget !== "undefined") {
        // PledgeTarget is actually not being stored as a settings
        newObj["pledgeTarget"] = pledgeTarget;
      }

      if (typeof investmentGrowthRate !== "undefined") {
        // PledgeTarget is actually not being stored as a settings
        newObj["settings.investmentGrowthRate"] = investmentGrowthRate;
      }
      if (typeof coastFIAge !== "undefined") {
        // PledgeTarget is actually not being stored as a settings
        newObj["settings.coastFIAge"] = coastFIAge;
      }

      if (
        typeof monthlyTakehome !== "undefined" &&
        typeof monthlySpend !== "undefined"
      ) {
        newObj["settings.monthlySavings"] = monthlyTakehome - monthlySpend;
      }

      newObj["pledgeMonthUpdatedOn"] = new Date();

      if (userRef && Object.keys(newObj).length > 0) {
        await updateDoc(userRef, newObj);
      }
    },
    async updateRegion(newRegion: "us" | "gb") {
      if (userRef) {
        await updateDoc(userRef, { region: newRegion });
      }
    },
    async removeAccount({ id }) {
      if (!id) return;

      await deleteDoc(doc(collection(userRef!, "accounts"), id));
    },
    async updateLastAccountContribution(data: any) {
      const accRef = userRef!.collection("accounts").doc(data.id);

      const currentAccData = (await accRef.get()).data()!;

      let newAccData = { ...currentAccData };

      let newData: any = {};

      if (data.hasOwnProperty("monthlyTopup")) {
        //TODO: I think we need to recalculate the last number based on the last month instead of just accounting for it this way
        const newAmount =
          parseInt(currentAccData.amount) -
          parseInt(currentAccData.monthlyTopup) +
          parseInt(data.monthlyTopup);
        newData.monthlyTopup = data.monthlyTopup;
        newData.amount = newAmount;
      }

      if (data.hasOwnProperty("annualReturn")) {
        //TODO: I think we need to recalculate the last number based on the last month instead of just accounting for it this way
        newData.annualReturn = data.annualReturn;
      }
      if (data.hasOwnProperty("amount")) {
        newData.amount = data.amount;
      }

      if (data.hasOwnProperty("date")) {
        // If date has been set, then update the history for that month
        newAccData.history[data.date] = {
          ...(newAccData.history[data.date] || {}),
          ...newData,
        };
      }

      if (!data.ignoreCurrent) {
        newAccData = { ...newAccData, ...newData };
      }

      await userRef!.collection("accounts").doc(data.id).update(newAccData);
    },
    async confirmRecentlyAddedAccounts({ updatedAccounts }) {
      let accIds = Object.keys(updatedAccounts);
      for (let index = 0; index < accIds.length; index++) {
        const accID = accIds[index];
        let updatedValue = cleanObject(updatedAccounts[accID]);
        if (Object.keys(updatedValue).length > 0) {
          if (updatedValue.manualMonthlyTopup) {
            // We need to override the history
            updatedValue[
              `history.${moment()
                .add(-1, "months")
                .format("YYYY-MM")}.monthlyTopup`
            ] = updatedValue.manualMonthlyTopup;
          }

          // Make sure there is something to update
          await this.updateAccount({
            id: accID,
            data: updatedValue,
          });
        }
      }
    },
    async updateIncome({ income }) {
      let salary = getMonthlyTakehomeFromIncome(income);

      let updateObj: Partial<UserModel> = {};

      if (salary) {
        updateObj["settings.monthlyTakehome"] = salary;
      }

      updateObj.incomes = income;

      await userRef!.update({
        ...updateObj,
      });
    },
  };

  const isLoading = authCtx.user && !authCtx.user?.isAnonymous && !initialized;

  return (
    <DataContext.Provider
      value={{
        modelArgs,
        fipreneurData,
        uid: uid!,
        user: user!,
        accounts: formattedAccounts,
        lifeEvents,
        modelResult,
        userRef,
        newToFI,
        lastMonthNetWorth,
        getUser,
        getAccounts,
        estNetWorthAccts,
        displayProgressReport: !!user?.displayProgressReport,
        operations,
        topiaRegion,
        history,
      }}
    >
      {isLoading ? <FullScreenTopiaLoader /> : children}
    </DataContext.Provider>
  );
};

export default DataProvider;
export const useDataContext = () => useContext(DataContext);

export const DEFAULT_SETTINGS = {
  withdrawalRate: 4,
  inflation: 3,
  investmentGrowthRate: 7,
  monthlyTakehome: 3500,
  monthlySavings: 1000,
  coastFIAge: 60,
};

export function _autoCalculateSettings(user: UserModel) {
  let settings: Settings = { ...user.settings };

  if (!settings.hasOwnProperty("monthlyTakehome")) {
    // Hardcode this value
    settings.monthlyTakehome = DEFAULT_SETTINGS.monthlyTakehome;
  }

  if (user.incomes) {
    // Get the monthly takehome from all of the incomes combined
    settings.monthlyTakehome = getMonthlyTakehomeFromIncome(user.incomes);
  }

  if (
    settings.hasOwnProperty("monthlySpend") &&
    settings.hasOwnProperty("monthlyTakehome")
  ) {
    settings.monthlySavings =
      settings.monthlyTakehome - Number(settings.monthlySpend);
  } else {
    if (!settings.hasOwnProperty("monthlySavings")) {
      settings.monthlySavings = DEFAULT_SETTINGS.monthlySavings;
    }
  }

  if (!settings.hasOwnProperty("withdrawalRate")) {
    settings.withdrawalRate = DEFAULT_SETTINGS.withdrawalRate;
  }

  if (!settings.hasOwnProperty("inflation")) {
    settings.inflation = 0;
  }

  if (!settings.hasOwnProperty("coastFIAge")) {
    // @ts-ignore
    settings.coastFIAge = DEFAULT_SETTINGS.coastFIAge;
  }

  if (!settings.hasOwnProperty("retirementSpent")) {
    settings.retirementSpent =
      settings.monthlyTakehome - settings.monthlySavings;
  }

  if (!settings.hasOwnProperty("retirementIncome")) {
    settings.retirementIncome = 1000;
  }

  // if (!settings.hasOwnProperty("expenditureType")) {
  //   settings.expenditureType = ExpenditureTypes.CURRENT;
  // }

  if (!settings.hasOwnProperty("netSalary")) {
    // @ts-ignore
    settings.netSalary = settings.monthlyTakehome * 12;
  }

  if (!settings.hasOwnProperty("partnerEnabled")) {
    settings.partnerEnabled = false;
  }
  if (!settings.hasOwnProperty("postFIType")) {
    // @ts-ignore
    settings.postFIType = "lean"; //FIType.NORMAL;
  }
  return settings;
}

/**
 * Get The users accounts
 *
 * @param {string} user id
 * @returns {object}
 */
const formatAccounts = (
  inputAccounts: Account[],
  settings: Settings
): Account[] => {
  const accounts = inputAccounts.map((acct) => {
    let extraData: any = {};

    const baseSubType =
      acct.type === "standard"
        ? ACCOUNT_SUBTYPES.CASH
        : acct.type === "saving"
        ? ACCOUNT_SUBTYPES.OTHER
        : ACCOUNT_SUBTYPES.MORTGAGE;

    const baseCategory =
      acct.type === "saving" || acct.type === "standard"
        ? ACCOUNT_CATEGORIES.OTHER
        : ACCOUNT_CATEGORIES.DEBT;

    let subType = acct.subType || baseSubType;

    if (
      acct.subType === ACCOUNT_SUBTYPES.STATE_PENSION ||
      acct.subType === ACCOUNT_SUBTYPES.DEFINED_BENEFITS_PENSION
    ) {
      // Calculate what the expected value will be for this asset
      // TODO: add in Withdrawal rate here
      extraData.modelledAsset = (acct.monthlyTopup * 12) / 0.04;
      // @ts-ignore
      extraData.modelledAssetTime = moment(acct.dateAccessed).diff(
        moment(),
        "months"
      );
    }
    if (
      !acct.hasOwnProperty("monthlyTopup") ||
      typeof acct.monthlyTopup === "undefined"
    ) {
      acct.monthlyTopup = 0;
    }

    // Transfer to v2 system
    // Check if there are any old debt accounts that need to be moved over
    //

    if (
      // @ts-ignore
      acct.accountProviderName &&
      // @ts-ignore
      acct.accountProviderName.toLowerCase().indexOf("vanguard") > -1
    ) {
      // @ts-ignore
      acct.accountProviderName = "Vanguard";
    }
    if (
      // @ts-ignore
      acct.accountProviderName &&
      // @ts-ignore
      acct.accountProviderName.toLowerCase().indexOf("aj bell") > -1
    ) {
      // @ts-ignore
      acct.accountProviderName = "AJ Bell";
    }

    if (acct.subType === ACCOUNT_SUBTYPES.MORTGAGE) {
      acct.category = ACCOUNT_CATEGORIES.MORTGAGE; // Make sure the category is right for mortgages
      if (!acct.property) {
        acct.property = {
          valueOfProperty: 100000,
          monthlyIncome: 0,
          includeHomeEquity: true,
          appreciationValue: 4,
        };
      }
    }

    let annualReturn = Number(acct.annualReturn) || 0;

    if (acct.category === ACCOUNT_CATEGORIES.EMERGENCY_FUND) {
      // Make annualReturn for emergency accounts be hardcoded to 0
      annualReturn = 0;
    }

    return {
      ...extraData,
      ...acct,
      id: `${acct.id}`,
      category: acct.category || baseCategory,
      subType: subType,
      amount: Number(acct.amount || 0),
      // monthlyTopup: parseFloat(docData.monthlyTopup || 0), // Replace the monthly topup by the manual overwritten value
      monthlyTopup: Number(acct.manualMonthlyTopup || acct.monthlyTopup || 0), // Replace the monthly topup by the manual overwritten value
      // TODO: will changing the manualMonthlyTopup here overwrite it in the future?
      annualReturn,
      withdrawalRate: acct.withdrawalRate || settings.withdrawalRate || 4,
    };
  });

  /**
   * We used to be able to disable accounts
   * Now it is only used for "future" accounts within tinker
   */
  return accounts.filter((acct) => !acct.from || !acct.disabled);
};

// const syncPlaidTokensAndItems = async (
//   docRef: FirebaseFirestoreTypes.DocumentReference<FirebaseFirestoreTypes.DocumentData>,
//   accounts: any[],
// ) => {
//   let access_tokens: string[] = [];
//   let item_ids: string[] = [];

//   accounts.forEach((acc) => {
//     if (acc.plaid_token) {
//       access_tokens.push(acc.plaid_token);
//     }
//     if (acc.plaid_item_id) {
//       item_ids.push(acc.plaid_item_id);
//     }
//   });

//   access_tokens = [...new Set(access_tokens)]; // Unique access tokens
//   item_ids = [...new Set(item_ids)]; // Unique access tokens

//   await docRef.update({
//     plaid_access_tokens: access_tokens,
//     plaid_item_ids: item_ids,
//   });
// };

export const cleanObject = (obj: any) => {
  for (var propName in obj) {
    if (typeof obj[propName] === "object") {
      cleanObject(obj[propName]);
    } else if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName];
    }
  }
  return obj;
};

export async function saveUser(vals, uid) {
  const userRecord: UserModel = {
    initialSettings: {},
    settings: {},
  } as any;

  const newSettings = generateFullUserSettings(userRecord);

  userRecord.region = vals.region;
  userRecord.settings.monthlyTakehome = Number(vals.monthlyTakehome);
  // @ts-ignore
  userRecord.settings.monthlyPassiveIncome = Number(vals.monthlyPassiveIncome);
  userRecord.settings.monthlySpend = Number(vals.monthlySpend);
  userRecord.settings.retirementSpent = Number(vals.retirementSpend);
  // @ts-ignore
  userRecord.settings.dateOfBirth = moment(vals.dateOfBirth).format(
    "DD/MM/YYYY"
  );

  // TODO
  // @ts-ignore
  userRecord.settings.estimatedNetWorth = Number(vals.estimatedNetWorth);

  userRecord.settings.inflation = Number(newSettings.inflation);
  userRecord.settings.withdrawalRate = Number(newSettings.withdrawalRate);

  userRecord.incomes = [
    {
      amount: Number(vals.monthlyTakehome),
      id: "salary",
      name: "Salary",
      type: "salary",
    },
  ];

  if (vals.monthlyPassiveIncome && Number(vals.monthlyPassiveIncome) > 0) {
    userRecord.incomes.push({
      amount: Number(vals.monthlyPassiveIncome),
      id: "passive-income",
      name: "Passive Income",
      type: "sidehustle",
    });
  }

  userRecord.initialSettings = userRecord.settings;
  userRecord.registeredOn = new Date();
  userRecord.settings.forceOnboarding = vals.forceOnboarding;
  //   @ts-ignore
  userRecord.registeredOnPlatform = "web";

  await setDoc(doc(collection(db, "users"), uid), cleanObject(userRecord));

  return newSettings;
}
