import classNames from "classnames";
import { Fragment, useState } from "react";
import { appActions, useAppDispatch, useAppSelector } from "../store";
import { Menu, Transition } from "@headlessui/react";
import { CogIcon } from "@heroicons/react/24/outline";
import { useAuthContext } from "../auth";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { TopiaLogo } from "./core/TopiaLogo";

import { TourProvider } from "@reactour/tour";
import { TourSteps } from "./TourSteps";
import TourNextButton from "./TourNextButton";
import { updateDoc } from "firebase/firestore";
import { useDataContext } from "../context/DataContext";
import { TopiaButton } from "./core/TopiaButton";

export function ApplicationShell(props: { children: any }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const isTinkerMode = useAppSelector((state) => !!state.app.tinkerData);
  const doFullOnboarding = useAppSelector(
    (state) => state.app.doFullOnboarding
  );
  const dispatch = useAppDispatch();
  const authCtx = useAuthContext();
  const dataCtx = useDataContext();
  const [step, setStep] = useState(0);

  const setCurrentStep = (step) => {
    if (doFullOnboarding) {
      switch (step) {
        case 2:
          navigate("/");
          break;
        case 3:
          navigate("/cashflow-fi");
          break;
        case 6:
          navigate("/cashflow-fi");
          break;
        case 7:
          navigate("/tinker");
          break;
        default:
          break;
      }
    }
    setStep(step);
  };
  const location = useLocation();
  const navigate = useNavigate();

  const tabs: (
    | {
        type?: undefined;
        name: string;
        href: string;
        current: boolean;
      }
    | {
        type: "header";
        name: string;
      }
  )[] = [
    {
      type: "header",
      name: "FI-Preneurs",
    },
    {
      name: "Start Here",
      href: "/",
      current: location?.pathname === "/",
    },
    {
      type: "header",
      name: "Athena",
    },
    {
      name: "Amazon FBA Idea Generator",
      href: "/amazon-fba-idea-generator",
      current: location?.pathname === "/amazon-fba-idea-generator",
    },
  ];

  return (
    <TourProvider
      steps={TourSteps}
      currentStep={step}
      setCurrentStep={setCurrentStep}
      startAt={0}
      onClickClose={({ setIsOpen }) => {
        setIsOpen(false);
        dispatch(appActions.setDoFullOnboarding(false));
      }}
      nextButton={({ Button, currentStep, setCurrentStep, stepsLength }) => {
        return (
          <TourNextButton
            pathName={location.pathname}
            Button={Button}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            stepsLength={stepsLength}
            onLastStep={(path) => {
              if (doFullOnboarding) {
                navigate("/");
                dispatch(appActions.setDoFullOnboarding(false));
              } else {
                navigate(path);
              }
            }}
          />
        );
      }}
    >
      <div className="flex h-full">
        {/* Static sidebar for desktop */}
        <div className="hidden lg:flex lg:flex-shrink-0 h-full ">
          <div className="flex w-full flex-col topia-bounce-in">
            <div
              className="topia-bounce-in h-full min-h-screen p-2"
              style={{ background: "#262729" }}
            >
              <div className="flex flex-row justify-between mt-2 p-2 mb-4">
                <TopiaLogo color="white" className="w-[120px] h-auto" />
              </div>
              <nav className="flex flex-col" aria-label="Tabs">
                {tabs.map((tab, ix) => (
                  <>
                    {tab.type === "header" ? (
                      <h5
                        className="text-xl text-white  px-3 py-2 mt-2"
                        key={ix}
                      >
                        {tab.name}
                      </h5>
                    ) : (
                      <Link
                        key={ix}
                        to={(tab as any).href}
                        className={classNames(
                          (tab as any).current
                            ? "text-pale-yellow font-black bg-white/10"
                            : "text-white hover:text-white hover:bg-white/20",
                          "px-3 py-2 font-bold text-xs lg:text-sm transition-all rounded-lg min-w-[240px]"
                        )}
                        aria-current={(tab as any).current ? "page" : undefined}
                      >
                        {(tab as any).name}
                      </Link>
                    )}
                  </>
                ))}
                {/* <a
                  href="https://topia-app.com/become-a-fipreneur"
                  target="_blank"
                  className="text-white text-xs font-bold px-3 py-2 lg:text-sm transition-all rounded-xl"
                  rel="noreferrer"
                >
                  Become an FI-Prenuer
                </a> */}
              </nav>
            </div>
          </div>
        </div>

        <div className="flex min-w-0 flex-1 flex-col">
          <div className="relative z-0 flex flex-1 ">
            <main className="relative z-0 flex-1 focus:outline-none xl:order-last">
              {/* Start main area*/}
              <div className="">{props.children}</div>
              {/* End main area */}
            </main>
          </div>
        </div>
      </div>
    </TourProvider>
  );
}
