import { useTour } from "@reactour/tour";
import { last } from "lodash";
import { Dispatch, useEffect } from "react";
import { useNavigate } from "react-router-dom";
type NavButtonProps = {
  onClick?: () => void;
  kind?: "next" | "prev";
  hideArrow?: boolean;
};
type BtnFnProps = {
  Button: React.FC<NavButtonProps>;
  setCurrentStep: Dispatch<React.SetStateAction<number>>;
  stepsLength: number;
  currentStep: number;
  // setIsOpen: Dispatch<React.SetStateAction<Boolean>>;
  onLastStep: (path) => void;
  pathName?: string;
};
const TourNextButton = (props: BtnFnProps) => {
  const { setIsOpen } = useTour();
  const navigate = useNavigate();
  const lastStep = props.currentStep === props.stepsLength - 1;
  const pathName = props.pathName ? props.pathName : "/";

  return (
    <props.Button
      onClick={() => {
        if (lastStep) {
          props.onLastStep(pathName);
          setIsOpen(false);
        } else {
          props.setCurrentStep((prev) => prev + 1);
        }
      }}
    ></props.Button>
  );
};
export default TourNextButton;
