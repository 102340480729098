import React, { useState, Fragment, useContext, useEffect } from "react";
import "./App.css";
import { store } from "./store";
import { Provider } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { ModalContext, ModalProvider } from "./context/ModalContext";
import { ApplicationShell } from "./components/ApplicationShell";
import { LoginPage } from "./pages/LoginPage";
import { Navigate, Router, RouterProvider } from "react-router-dom";
import { router } from "./router";
import { AuthProvider } from "./auth";
import DataContext from "./context/DataContext";
import { TinkerProvider } from "./context/TinkerContext";
import { FIPReneurProvider } from "./context/FIPreneurContext";

export function GlobalModal(props: {}) {
  const modalCtx = useContext(ModalContext);
  return (
    <Transition.Root show={!!modalCtx.modal && modalCtx.open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        style={{
          width: 900,
        }}
        onClose={() => modalCtx.closeModal()}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative transform px-4 pt-8 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6 bg-topia-black border-2 max-h-[90vh] overflow-auto border-white/20 rounded-2xl`}
              >
                <div className="absolute top-4 right-4 ">
                  <XCircleIcon
                    className="w-8 h-8 z-10 text-white cursor-pointer hover:text-future-blue"
                    onClick={() => {
                      modalCtx.closeModal();
                    }}
                  />
                </div>
                <div className="mt-4">{modalCtx.modal}</div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

function App() {
  return (
    <Provider store={store}>
      <ModalProvider>
        <AuthProvider>
          <DataContext>
            <TinkerProvider>
              <FIPReneurProvider>
                {/* <TourProvider steps={TourSteps}> */}
                <RouterProvider router={router} />
                {/* </TourProvider> */}

                <GlobalModal />
              </FIPReneurProvider>
            </TinkerProvider>
          </DataContext>
        </AuthProvider>
      </ModalProvider>
    </Provider>
  );
}

export default App;
